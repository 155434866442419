import store from "@/store";
export const requireAuth = (to, from, next) => {
  if (store.getters.token != null) {
    next();
  } else if (localStorage.getItem("token") != null) {
    next();
  } else {
    next({
      replace: true,
      path: "/auth/login"
    });
  }
};
