import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import "vuetify/src/styles/main.sass";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi"
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#00776F", // Dark Green
        secondary: "#07B59F", // Light Green
        grey: "#6F6F6E",
        lightGrey: "#F5F4F4",
        orange: "#DB9940",
        purple: "#B580B5",
        red: "#C75E40",
        oliveGreen: "#B0AB2E",
        yellow: "#E5BD14"
      }
    }
  }
});
