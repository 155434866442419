export const BASE_URL = process.env.VUE_APP_BASE_URL;
export const WS_BASE_URL = process.env.VUE_APP_WS_BASE_URL;
export const CLIENT_SERVICE_PORT =
  process.env.VUE_APP_CLIENT_SERVICE_PORT != null
    ? process.env.VUE_APP_CLIENT_SERVICE_PORT
    : "";
export const REQUESTS_SERVICE_PORT =
  process.env.VUE_APP_REQUESTS_SERVICE_PORT != null
    ? process.env.VUE_APP_REQUESTS_SERVICE_PORT
    : "";
export const FEEDBACK_SERVICE_PORT =
  process.env.VUE_APP_FEEDBACK_SERVICE_PORT != null
    ? process.env.VUE_APP_FEEDBACK_SERVICE_PORT
    : "";
export const MESSAGES_SERVICE_PORT =
  process.env.VUE_APP_MESSAGES_SERVICE_PORT != null
    ? process.env.VUE_APP_MESSAGES_SERVICE_PORT
    : "";

export const WS_SERVICE_PORT =
  process.env.VUE_APP_WS_SERVICE_PORT != null
    ? process.env.VUE_APP_WS_SERVICE_PORT
    : "";

export const SITE = process.env.VUE_APP_SITE;
export const ENV = process.env.VUE_APP_ENV;
export const SHOW_CQC = JSON.parse(process.env.VUE_APP_SHOW_CQC);
export const MFA_ENABLED = JSON.parse(process.env.VUE_APP_MFA_ENABLED);
export const SHOW_CHATS = JSON.parse(process.env.VUE_APP_SHOW_CHATS);
