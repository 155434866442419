<template>
  <div id="app" style="position: relative;">
    <v-app>
      <v-snackbar
        :timeout="timeout"
        :top="true"
        :right="true"
        :color="color"
        :text="im"
        v-model="snackbar"
        class="snackbar"
      >
        {{ text }}
        <v-btn icon @click.native="goto(snack_link)" v-if="snack_link != null">
          <v-icon>mdi-arrow-right-bold</v-icon>
        </v-btn>

        <v-btn icon @click.native="snackbar = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-snackbar>

      <router-view />
      <v-bottom-sheet
        v-if="env === 'PRODUCTION'"
        class="cookie-notice"
        v-model="showCookieNotice"
        :inset="true"
        :persistent="true"
        :hide-overlay="true"
      >
        <v-card-text class="cookie">
          <p>
            We use cookies to provide you with the best experience when you
            browse our website and also allow us to improve our site.
          </p>
          <v-btn rounded large color="primary" @click="acceptCookieNotice"
            >Accept</v-btn
          >
          <v-btn rounded large @click="declineCookieNotice" id="decline-btn"
            >Decline</v-btn
          >
        </v-card-text>
      </v-bottom-sheet>
    </v-app>
  </div>
</template>

<script>
import { ENV } from "@/config";
import EventBus from "@/event-bus";
export default {
  data() {
    return {
      showCookieNotice: false,
      color: "color",
      snackbar: false,
      timeout: 2000,
      text: "placeholder",
      im: false,
      snack_link: null,
      env: ENV
    };
  },
  methods: {
    acceptCookieNotice() {
      this.showCookieNotice = false;
      localStorage.setItem("cookie_notice", "true");
      this.$gtag.optIn();
    },
    declineCookieNotice() {
      this.showCookieNotice = false;
      localStorage.setItem("cookie_notice", "true");
    },
    goto(path) {
      this.$router.push({ path });
    }
  },
  computed: {
    multiLine() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
    not_mobile() {
      return !this.$vuetify.breakpoint.xs;
    }
  },
  mounted() {
    this.showCookieNotice =
      localStorage.getItem("cookie_notice") === "true" ? false : true;
    EventBus.$on("toast", message => {
      this.text = message.text;
      this.color = message.type;
      this.snackbar = true;
      this.im = message.im ?? false;
      this.snack_link = message.snack_link;
    });
  }
};
</script>

<style scoped>
.snackbar {
  padding-top: 96px !important;
}

::v-deep .v-snack__wrapper {
  min-width: fit-content;
}

::v-deep .v-snack__content {
  padding-right: 0;
}

::v-deep .v-btn__content {
  font-size: 20px;
  letter-spacing: normal;
}

::v-deep .v-application a {
  color: var(--v-grey-base);
}

.cookie {
  padding: 20px;
  font-size: 1rem;
  background-color: white;
  display: flex;
  align-items: center;
  width: fit-content;
}
.cookie p {
  margin: 0;
}

.cookie button {
  margin-left: 15px;
}

#decline-btn {
  background-color: #e8e8e8;
}

.cookie-notice {
  background-color: white;
}

@media only screen and (max-width: 960px) {
  .cookie {
    display: inherit;
    align-items: left;
  }
  .cookie button {
    margin-left: 5px;
  }
  .cookie p {
    margin: 10px;
  }
}
</style>
