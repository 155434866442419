import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: null,
    self: null,
    last_seen: null,
    messages: {},
    chats: []
  },
  getters: {
    token(state) {
      return state.token;
    },
    self(state) {
      return state.self;
    },
    last_seen(state) {
      return state.last_seen;
    },
    messages(state) {
      return state.messages;
    },
    chat_messages: state => chat_id => {
      return state.messages[chat_id];
    },
    mostRecentChatID(state, getters) {
      if (getters.sortedChats.length === 0) {
        return "";
      }
      return getters.sortedChats[0].id;
    },
    message_sender: state => (chat_id, sender_id) => {
      return state.chats.find(chat => chat.id === chat_id)?.participants[
        sender_id
      ];
    },
    chat: state => chat_id => {
      if (state.chats.length != 0) {
        let chat = state.chats.find(chat => chat.id === chat_id);
        if (chat != null) {
          return chat;
        }
      }
      return {};
    },
    chats(state) {
      return state.chats;
    },
    sortedChats(state) {
      return state.chats.sort(
        (a, b) =>
          (a.last_message !== null && "created_at" in a.last_message
            ? Date.parse(a.last_message.created_at)
            : Date.parse(a.created_at)) -
          (b.last_message !== null && "created_at" in b.last_message
            ? Date.parse(b.last_message.created_at)
            : Date.parse(b.created_at))
      );
    },
    hasUnreadMessages(state) {
      let last_seen = state.last_seen;
      let chats = state.chats;
      return chats.filter(
        x => x?.last_message?.created_at > last_seen && !x?.has_seen_last
      ).length;
    },
    chatExists: state => id => {
      let chats = state.chats;
      console.log(id);
      return chats.find(c => c.id === id) !== undefined;
    }
  },
  mutations: {
    set_token(state, t) {
      state.token = t;
    },
    set_self(state, s) {
      state.self = s;
    },
    set_last_seen(state, ls) {
      state.last_seen = ls;
    },
    add_message(state, { chat_id, msg }) {
      if (state.messages[chat_id] == null) {
        state.messages = { ...state.messages, [chat_id]: [] };
      }
      state.messages[chat_id].push(msg);
    },
    set_messages(state, { chat_id, msgs }) {
      state.messages = { ...state.messages, [chat_id]: msgs };
    },
    set_chats(state, chats) {
      state.chats = chats;
    },
    set_chat_last_message(state, { chat_id, msg }) {
      if (state.chats.length != 0) {
        let chat = state.chats.find(c => c.id == chat_id);
        chat.last_message = msg;
        state.chats = [chat, ...state.chats.filter(c => c.id != chat_id)];
      }
    },
    set_chat_has_seen_last(state, { chat_id, val }) {
      if (state.chats.length != 0) {
        let chat = state.chats.find(c => c.id == chat_id);
        chat.has_seen_last = val;
        state.chats = [chat, ...state.chats.filter(c => c.id != chat_id)];
      }
    }
  },
  actions: {}
});
