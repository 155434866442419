import Vue from "vue";
import Router from "vue-router";
import { requireAuth } from "@/auth.js";
import store from "@/store";
import { SITE } from "@/config.js";

const title = `${SITE.toLowerCase().replace(/^\w/, c =>
  c.toUpperCase()
)}Compare`;

const Auth = () => import("./containers/Auth.vue");
const Login = () => import("./views/auth/Login.vue");
const Register = () => import("./views/auth/Register.vue");
const ForgotPassword = () => import("./views/auth/Forgot-Password.vue");
const ResetPassword = () => import("./views/auth/Reset-Password.vue");

const Home = () => import("./containers/Home.vue");
const CareForm = () => import("./views/home/CareForm.vue");
const PhysioForm = () => import("./views/home/PhysioForm.vue");
const Form =
  SITE === "CARE" ? CareForm : SITE === "PHYSIO" ? PhysioForm : PhysioForm;
const Requests = () => import("./views/home/Requests.vue");
const Responses = () => import("./views/home/Responses.vue");
const Response = () => import("./views/home/Response.vue");
const Profile = () => import("./views/home/Profile.vue");
const Chats = () => import("./views/home/Chats.vue");
const Chat = () => import("./views/home/Chat.vue");

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      beforeEnter(to, from, next) {
        let token = store.getters.token ?? localStorage.getItem("token");
        const formCompleted = localStorage.getItem("formCompleted");
        if (token != null) {
          if (formCompleted === "true") {
            next({ replace: true, path: "/home/requests" });
          } else {
            next({ replace: true, path: "/home/form" });
          }
        } else {
          next({ replace: true, path: "/auth/login" });
        }
      }
    },
    {
      path: "/auth",
      name: "auth",
      component: Auth,
      meta: { title },
      children: [
        {
          path: "login",
          name: "login",
          component: Login
        },
        {
          path: "register",
          name: "register",
          component: Register
        },
        {
          path: "forgot-password",
          name: "forgot-password",
          component: ForgotPassword
        },
        {
          path: "reset-password",
          name: "reset-password",
          component: ResetPassword,
          props: route => ({ token: route.query.token })
        }
      ]
    },
    {
      path: "/home",
      name: "home",
      meta: { title },

      component: Home,
      beforeEnter: requireAuth,
      children: [
        {
          path: "form",
          name: "form",
          beforeEnter: requireAuth,
          component: Form
        },
        {
          path: "requests",
          name: "requests",
          component: Requests,
          beforeEnter: requireAuth
        },
        {
          path: "responses/:id",
          name: "responses",
          component: Responses,
          beforeEnter: requireAuth,
          props: true
        },
        {
          path: "response/:id",
          name: "response",
          component: Response,
          beforeEnter: requireAuth,
          props: true
        },
        {
          path: "profile",
          name: "profile",
          component: Profile,
          beforeEnter: requireAuth
        },
        {
          path: "chats",
          name: "chats",
          component: Chats,
          beforeEnter: requireAuth
        },
        {
          path: "chat/:id",
          name: "chat",
          component: Chat,
          beforeEnter: requireAuth,
          props: true
        }
      ]
    }
  ]
});
